<template>
    <div class="search-page">
        <GeekQooSearch></GeekQooSearch>
        <div class="top-title">
            <p>{{ keyword }}</p>
        </div>
        <div v-if="searchHistory.length>0" class="search-tags">
            <p class="item-title">搜索历史<a @click="clearHistory()">清除所有记录</a></p>
            <div class="item-list">
                <a v-for="(item,index) in searchHistory" :key="index" @click="historySearch(item)">{{ item }}</a>
            </div>
        </div>
        <!--        <div class="search-tags">-->
        <!--            <p class="item-title">功能搜索</p>-->
        <!--            <div class="item-list">-->
        <!--                <a>测试标签</a>-->
        <!--                <a>测试签</a>-->
        <!--                <a>测试标签</a>-->
        <!--                <a>测试签</a>-->
        <!--                <a>签签签签</a>-->
        <!--                <a>测试标签签</a>-->
        <!--                <a>测试签</a>-->
        <!--                <a>测试签</a>-->
        <!--                <a>测试标签</a>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="search-result">
            <p class="item-title">搜索结果</p>
            <div v-if="goodsList.length>0" class="item-content">
                <!--                <div class="category-tags">-->
                <!--                    <a v-for="(item,index) in category.list" :class="category.active==index?'active':''"-->
                <!--                       @click="categoryChange(index)">{{ item.name }}</a>-->
                <!--                </div>-->
                <div class="screening">
                    <input v-model="minPrice" class="item-input" placeholder="最低价" type="text">
                    <span class="item-word">一</span>
                    <input v-model="maxPrice" class="item-input" placeholder="最高价" type="text">
                    <van-button class="item-button" color="#F96719" round size="mini" @click="screenList()">筛选
                    </van-button>
                </div>
                <van-list
                    v-model="goodsPagination.loading"
                    :finished="goodsPagination.finished"
                    class="goods-list"
                    finished-text="没有更多了"
                    @load="getGoodsList"
                >
                    <div class="list-wrapper">
                        <a v-for="(item,index) in goodsList" class="good-item" @click="toDetail(item)">
                            <div :style="`background:url(${item.thumb_arr.length >0?item.thumb_arr[0]:item.thumb})`"
                                 class="item-img"></div>
                            <div class="item-info">
                                <p class="p1" v-if="item.bar_code">组名：{{ item.bar_code ? item.bar_code : "暂无" }}</p>
                                <p class="p1">货号：{{ item.title }}</p>
                                <p class="p1">名称：{{ item.sub_title }}</p>
                                <p v-if="item.price" class="p2">￥{{ item.price }}</p>
                                <p v-else class="p2">暂无报价</p>
                            </div>
                        </a>
                    </div>
                </van-list>
            </div>
            <!--搜索数据为空-->
            <div v-else class="item-empty">
                <van-empty description="没有搜到你想要的结果哦~">
                    <van-button class="back-button" color="#F28246" to="/home">
                        返回首页
                    </van-button>
                </van-empty>
            </div>
        </div>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch"
import {GOODS_LIST} from "@/api/dataProvider";
import {getLocalStorage,clearLocalStorage} from "@/utils/storage";

export default {
    name: "Search",
    mixins: [mixins_config],
    components: {
        GeekQooSearch
    },
    data() {
        return {
            // 搜索关键词
            keyword: this.$route.query.keyword,
            // 搜索历史
            searchHistory: [],
            // 分类
            category: {
                list: [
                    {name: "客厅系列"},
                    {name: "厨房系列"},
                    {name: "卧室系列"},
                    {name: "厕所系列"},
                ],
                active: 1
            },
            // 最低价
            minPrice: "",
            // 最高价
            maxPrice: "",
            // 商品列表
            goodsList: [],
            goodsPagination: {
                current: 1,
                loading: false,
                finished: false,
            }
        };
    },
    watch: {
        "$route.query.keyword": {
            handler(val) {
                if (this.$route.params.status != -1 && this.$route.name == "Search") {
                    this.keyword = val
                    this.clearPagination()
                    this.getGoodsList()
                }
            }
        }
    },
    created() {
        this.app.tabActive = -1
    },
    mounted() {
        this.init();
        this.getGoodsList()
    },
    methods: {
        init() {
            if (getLocalStorage("search")) {
                this.searchHistory = getLocalStorage("search").split("|")
            }
        },
        clearHistory(){
            clearLocalStorage("search")
            this.searchHistory = []
        },
        // 点击历史搜索列表
        historySearch(item) {
            this.clearPagination()
            this.$router.push({
                name: "Search",
                query: {
                    keyword: item
                }
            })
        },
        // 筛选
        screenList() {
            this.clearPagination()
            this.getGoodsList()
        },
        // 初始化分页信息
        clearPagination() {
            this.goodsPagination = {
                current: 1,
                loading: false,
                finished: false,
            }
            this.goodsList = []
        },
        // 获取列表
        getGoodsList() {
            let that = this
            GOODS_LIST(
                {
                    page: that.goodsPagination.current,
                    pageSize: 10,
                    keyword: that.keyword,
                    minPrice: that.minPrice,
                    maxPrice: that.maxPrice
                },
                function (res) {
                    if (res.data.code === 0) {
                        that.goodsPagination.loading = false
                        // 列表有数据并且是第一页，那肯定是重复请求了，所以阻断掉
                        if (that.goodsList.length > 0 && res.data.data.current_page == 1) {
                            return false
                        }
                        that.goodsList = that.goodsList.concat(res.data.data.data)
                        if (res.data.data.last_page > that.goodsPagination.current) {
                            that.goodsPagination.current += 1;
                        } else {
                            that.goodsPagination.finished = true;
                        }
                        console.log(res.data.data)
                    }
                }
            )
        },
        // 跳转商品详情
        toDetail(item) {
            this.$router.push({
                name: "GoodDetail",
                params: {id: item.id}
            })
        },
        categoryChange(index) {
            this.category.active = index
        }
    }
}
</script>

<style lang="scss">
.search-page {
    padding: 0 0 50px 0;

    .top-title {
        background: #F96719;

        p {
            line-height: 35px;
            text-align: center;
            font-size: 10px;
            color: #fff;
        }
    }

    .search-tags {
        margin-top: 21px;

        .item-title {
            display: flex;
            align-items: center;
            line-height: 28px;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 14px;
            font-weight: bold;
            color: #2D2D2D;
            border-left: 7px solid #F96719;
            a{
                margin-left: auto;
                font-size: 12px;
                color: #F96719;
                font-weight: normal;
            }
        }

        .item-list {
            a {
                display: inline-block;
                margin: 15px 10px 0 10px;
                padding: 5px 15px;
                font-size: 10px;
                color: #999;
                background: #eeeeee;
                border-radius: 21px;
            }
        }
    }

    .search-result {
        margin-top: 21px;

        .item-title {
            line-height: 28px;
            padding-left: 15px;
            font-size: 14px;
            font-weight: bold;
            color: #2D2D2D;
            border-left: 7px solid #F96719;
        }

        .item-content {
            .category-tags {
                padding: 10px 10px 0 10px;

                a {
                    display: inline-block;
                    min-width: calc(25% - 14px);
                    margin: 7px;
                    padding: 7px 0;
                    border: 1px solid #eeeeee;
                    text-align: center;
                    font-size: 10px;
                    color: #999999;
                }

                .active {
                    border-color: #F96719;
                    color: #F96719;
                }
            }

            .screening {
                margin: 7px 0 0 0;
                padding: 0 17px;
                text-align: center;

                .item-input {
                    vertical-align: middle;
                    width: 123px;
                    line-height: 24px;
                    background: #f8f8f8;
                    border: 1px solid #f8f8f8;
                    border-radius: 15px;
                    text-align: center;
                    font-size: 12px;
                    color: #333;
                }

                .item-word {
                    vertical-align: middle;
                    line-height: 24px;
                    margin: 0 10px;
                    font-size: 12px;
                    color: #333;
                }

                .item-button {
                    padding: 0 15px;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }

            .goods-list {
                padding: 0 17px 0 17px;

                .list-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .good-item {
                        display: block;
                        width: 163px;
                        margin-top: 14px;
                        padding: 3px;
                        border: 1px solid #EEEEEE;

                        .item-img {
                            width: 100%;
                            height: 94px;
                            background-repeat: no-repeat !important;
                            background-size: cover !important;
                            background-position: center !important;
                        }

                        .item-info {
                            padding: 10px;

                            .p1 {
                                margin-bottom: 10px;
                                font-size: 10px;
                                color: #2D2D2D;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .p2 {
                                font-size: 10px;
                                font-weight: bold;
                                color: #F54A4A;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }

        .item-empty {
            .back-button {
                width: 300px;
            }
        }
    }
}
</style>